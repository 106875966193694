<template>
  <div class='wrapper'>
    
    <div><Button class="btn" @click="goToView('ConsultConsents');">Home</Button></div>
    <div><Button class="btn" @click="goToView('MyConsents');">Consultar Consentimentos</Button></div>
    <div><Button class="btn" @click="goToView('ConfirmConsent');">Tela de Pagamento</Button></div>
    <div><Button class="btn" @click="goToView('faq');">FAQ</Button></div>
    <div><Button class="btn" @click="goToView('contactUs');">Atendimento</Button></div>
    <div><Button class="btn" @click="goToView('TppSimulator');">Simulador TPP</Button></div>
    <div><Button class="btn" @click="goToView('Home');">Documentação Componentes</Button></div>

  </div>
</template>

<script>

export default {
   
  data() {
    return {}
  },
  methods: {
    goToView(viewName){
      this.$router.push({name: viewName});
    }
  }
}
</script>

<style scoped>

.wrapper{
  background-color: var(--page-background-color);
  padding-top: 50px;
}
.wrapper div {
  text-align: center;
}
.btn{
  background-color: var(--button-confirmconsent-background-color);
  color: var(--button-confirmconsent-text-color);
  border: var(--button-confirmconsent-border);
  box-shadow: var(--button-confirmconsent-shadow);
  display: inline-block;
  margin: 0px auto;
  width: 80%;
  height: 60px;
  margin: 10px 10px;
  border-radius: 8px;
  align-items: center;
  font-size: 20px;
}
.btn:hover{
  background-color: var(--button-confirmconsent-background-hover-color);
  border: var(--button-confirmconsent-border) !important;
}

</style>